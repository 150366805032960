<template>
  <div>
    <b-col class="p-0">
      <b-card-actions
        :title="$t('Filters')"
        action-collapse
        :collapsed="collapsedFilter"
      >
        <b-card-body class="p-0">
          <b-row>
            <!-- Brand -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Brand') }}</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="brandFilter"
                :options="brandOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:brandFilter', val)"
              />
            </b-col>
            <!-- Merchant -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Merchant') }}</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="merchantFilter"
                :options="merchantOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:merchantFilter', val)"
              />
            </b-col>
            <!-- Value -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Value') }}</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="valueFilter"
                :options="valueOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:valueFilter', val)"
              />
            </b-col>
            <!-- Status -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Status') }}</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="statusFilter"
                :options="statusOptions"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:statusFilter', val)"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card-actions>
    </b-col>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    vSelect,
    BCardActions,
  },
  props: {
    collapsedFilter: {
      type: Boolean,
      default: false,
    },
    brandFilter: {
      type: [String, null],
      default: null,
    },
    merchantFilter: {
      type: [String, null],
      default: null,
    },
    valueFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    merchantOptions: {
      type: Array,
      required: true,
    },
    valueOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
