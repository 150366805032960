<template>
  <div
    v-if="$can('read', 'Coupon')"
    style="height: inherit"
  >

    <!-- Filters -->
    <coupon-list-filters
      :collapsed-filter.sync="collapsedFilter"
      :brand-filter.sync="brandFilter"
      :merchant-filter.sync="merchantFilter"
      :value-filter.sync="valueFilter"
      :status-filter.sync="statusFilter"
      :brand-options="brandOptions"
      :merchant-options="merchantOptions"
      :value-options="valueOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="checkSelected"
              class="custom-control-primary"
              switch
              @change="changeSelect"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <label>{{ $t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addCouponForm"
            >
              {{ `${$t('Add')} ${$t('Coupon')}` }}
            </b-button>
            <b-button
              class="ml-1"
              variant="success"
              @click="importCouponForm"
            >
              {{ `${$t('Import')} ${$t('Coupon')}` }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="`${$t('Search')}...`"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refCouponListTable"
        :items="getCoupons"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      >
        <!-- Column: Brand/Merchant -->
        <template #cell(brand_merchant)="data">
          <div style="margin-top: 0.6rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span class="align-text-top">{{ data.item.brand_name }}</span>
              </b-list-group-item>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span class="align-text-top">{{ data.item.merchant_name }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Image -->
        <template #cell(image)="data">
          <div class="text-nowrap">
            <b-img-lazy
              v-if="(data.item.image !== '')"
              :src="data.item.image"
              rounded
              width="80"
              blank
              blank-width="80"
              blank-color="#eeeeee"
            />
            &nbsp;
            <b-img-lazy
              v-if="(data.item.image_en !== '')"
              :src="data.item.image_en"
              rounded
              width="80"
              blank
              blank-width="80"
              blank-color="#eeeeee"
            />
          </div>
        </template>

        <!-- Column: Coupon -->
        <template #cell(coupon)="data">
          <div>
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-info` : `info`"
            >
              <span style="font-size: 14px;">{{ data.item.code }}</span>
            </b-badge>
          </div>
          <div style="margin-top: 0.6rem;">
            <b-list-group horizontal="md">
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 4rem;"
              >
                <small>{{ $t('Start') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.start_date !== '')? data.item.start_date : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 4rem;"
              >
                <small>{{ $t('Stop') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.stop_date !== '')? data.item.stop_date : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Value/Create/By -->
        <template #cell(value)="data">
          <div>
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-success` : `success`"
              class="text-capitalize"
            >
              ฿ {{ data.item.value }}
            </b-badge>
          </div>
          <div style="margin-top: 0.6rem;">
            <b-list-group horizontal="md">
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 4.4rem;"
              >
                <small>{{ $t('Create') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.create_datetime !== '')? data.item.create_datetime : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 4.4rem;"
              >
                <small>{{ $t('By') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.create_by !== '')? data.item.create_by : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div>
            <b-badge
              pill
              :variant="(skin === 'dark')? `light-${resolveCouponStatusVariant(data.item.status)}` : `${resolveCouponStatusVariant(data.item.status)}` "
              class="text-capitalize"
            >
              {{ $t(textFirstUpper(data.item.status)) }}
            </b-badge>
          </div>
          <div
            v-if="!!data.item.redeem"
            style="margin-top: 0.6rem;"
          >
            <b-list-group horizontal="md">
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 6rem;"
              >
                <small>{{ $t('Reference') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.redeem.reference !== '')? data.item.redeem.reference : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              style="margin-top: 0.2rem;"
              horizontal="md"
            >
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 6rem;"
              >
                <small>{{ $t('Ref ID') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.redeem.ref_id !== '')? data.item.redeem.ref_id : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div
            v-if="!!data.item.refund"
            style="margin-top: 0.2rem;"
          >
            <b-list-group horizontal="md">
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; width: 6rem;"
              >
                <small>{{ $t('Note') }}</small>
              </b-list-group-item>
              <b-list-group-item style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;">
                <small>{{ (data.item.refund.note !== '')? data.item.refund.note : '-' }}</small>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`coupon-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="editCouponForm(data)"
            />
            <b-tooltip
              :title="`${$t('Edit')} ${$t('Coupon')}`"
              :target="`coupon-row-${data.item.id}-edit-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="left"
            />

            <feather-icon
              :id="`coupon-row-${data.item.id}-view-icon`"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="viewCouponForm(data)"
            />
            <b-tooltip
              :title="`${$t('View')} ${$t('Coupon')}`"
              :target="`coupon-row-${data.item.id}-view-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="left"
            />

            <feather-icon
              :id="`coupon-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              variant="light-success"
              size="16"
              class="cursor-pointer mr-1"
              @click="deleteCouponForm(data)"
            />
            <b-tooltip
              :title="`${$t('Delete')} ${$t('Coupon')}`"
              :target="`coupon-row-${data.item.id}-delete-icon`"
              :delay="{ show: 1000, hide: 50 }"
              placement="right"
            />

          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('Showing {from} to {to} of {of} entries',
                    {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCoupons"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BPagination, BTooltip, BFormCheckbox, BListGroup, BListGroupItem,
  BImgLazy,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import CouponListFilters from './CouponListFilters.vue'
import useCouponList from './useCouponList'
import couponStoreModule from '../couponStoreModule'

export default {
  components: {
    CouponListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BImgLazy,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      defaultData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      checkSelected: false,
      collapsedFilter: false,
    }
  },
  Computed: {
    checkSelected() {
      return !this.checkSelected
    },
  },
  mounted() {
    this.getDefaultCoupon()
  },
  methods: {
    getDefaultCoupon() {
      store
        .dispatch('coupon-store/getDefaultCoupon', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.defaultData = response.data
          this.brandOptions = this.defaultData.brand_options
          this.merchantOptions = this.defaultData.merchant_options
          this.valueOptions = this.defaultData.value_options
          this.statusOptions = this.defaultData.status_options
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    onRowSelected(items) {
      this.selected = items
      if (this.selected.length > 0) {
        this.checkSelected = true
      } else {
        this.checkSelected = false
      }
    },
    changeSelect() {
      if (this.checkSelected === true) {
        this.$refs.refCouponListTable.selectAllRows()
      } else {
        this.$refs.refCouponListTable.clearSelected()
      }
    },
    addCouponForm() {
      this.$router.push({ name: 'ecoupon-coupon-add' })
    },
    editCouponForm(data) {
      this.$router.push({ name: 'ecoupon-coupon-edit', params: { id: data.item.id } })
    },
    viewCouponForm(data) {
      this.$router.push({ name: 'ecoupon-coupon-view', params: { id: data.item.id } })
    },
    deleteCouponForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('coupon-store/deleteCoupon', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    importCouponForm() {
      this.$router.push({ name: 'ecoupon-coupon-import' })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'coupon-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, couponStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const brandOptions = []

    const merchantOptions = []

    const valueOptions = []

    const statusOptions = []

    const {
      getCoupons,
      tableColumns,
      perPage,
      currentPage,
      totalCoupons,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCouponListTable,
      refetchData,

      // UI
      resolveCouponStatusVariant,
      textFirstUpper,

      // Extra Filters
      brandFilter,
      merchantFilter,
      valueFilter,
      statusFilter,
    } = useCouponList()

    const filterCouponList = JSON.parse(localStorage.getItem('filterCouponList'))
    if (filterCouponList !== null) {
      searchQuery.value = (filterCouponList.searchQuery !== null) ? filterCouponList.searchQuery : ''
      perPage.value = (filterCouponList.perPage !== null) ? filterCouponList.perPage : ''
      currentPage.value = (filterCouponList.page !== null) ? filterCouponList.page : ''
      sortBy.value = (filterCouponList.sortBy !== null) ? filterCouponList.sortBy : ''
      isSortDirDesc.value = (filterCouponList.sortDesc !== null) ? filterCouponList.sortDesc : ''
      brandFilter.value = (filterCouponList.brand !== null) ? filterCouponList.brand : ''
      merchantFilter.value = (filterCouponList.merchant !== null) ? filterCouponList.merchant : ''
      valueFilter.value = (filterCouponList.value !== null) ? filterCouponList.value : ''
      statusFilter.value = (filterCouponList.status !== null) ? filterCouponList.status : ''
    }

    return {
      getCoupons,
      tableColumns,
      perPage,
      currentPage,
      totalCoupons,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCouponListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveCouponStatusVariant,
      textFirstUpper,

      brandOptions,
      merchantOptions,
      valueOptions,
      statusOptions,

      // Extra Filters
      brandFilter,
      merchantFilter,
      valueFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.coupon-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/e-coupon/coupon.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
